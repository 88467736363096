import prodConfig from "./environment/prod.json";
import testConfig from "./environment/test.json";
import devConfig from "./environment/dev.json";
import amplitudeDevConfig from "./environment/amplitude_dev.json";
import commonValues from "./common.json";

enum Env {
  PRODUCTION = "production",
  TEST = "test",
  DONGHUN = "donghun",
  ADRIEL = "adriel",
  JISU = "jisu",
  SOMI = "somi",
  NOAH = "noah",
  JUNGHYUN = "junghyun",
  HAMZA = "hamza",
  PHIL = "phil",
  DEVELOPMENT = "development",
  AMPLITUDE_DEVELOPMENT = "amplitude_development",
}

const currentEnv = Env.PRODUCTION; // Change THIS

const getTestServerSetting = (env: Env) => {
  return {
    slid_web_site_host: `${env}.test.slid.cc`,
    slid_web_app_host: `app.${env}.test.slid.cc`,
    slid_web_site: `https://${env}.test.slid.cc`,
    slid_web_app: `https://app.${env}.test.slid.cc`,
  };
};

const getEnvConfig = (env: Env) => {
  switch (env) {
    case Env.PRODUCTION:
      return prodConfig;
    case Env.TEST:
      return testConfig;
    case Env.DEVELOPMENT:
      return devConfig;
    case Env.AMPLITUDE_DEVELOPMENT:
      return amplitudeDevConfig;
    // Name-test env
    case Env.DONGHUN:
    case Env.ADRIEL:
    case Env.JISU:
    case Env.SOMI:
    case Env.NOAH:
    case Env.JUNGHYUN:
    case Env.HAMZA:
    case Env.PHIL:
      return { ...testConfig, end_point_url: { ...testConfig.end_point_url, ...getTestServerSetting(env) } };
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
};

export default { currentEnv, ...getEnvConfig(currentEnv), ...commonValues };
